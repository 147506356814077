const baseFitmentFields = ['Make', 'Year', 'Model'];
const extraFitmentFields = [];

const categorySelectionPageUrl = '/categories';

export default {
  platform: 'bigcommerce',
  searchPageUrl: '/pages/search/',
  SearchRequestDefaults: {
    pageSize: 36,
    sort: 'price:desc',
  },
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl ? 'parts' : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [{ pathname: categorySelectionPageUrl, field: 'category' }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.template-category .container .page',
      template: 'SearchPage',
      visibleIf: () => window.location.pathname !== categorySelectionPageUrl,
    },
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      facetField: 'category',
      visibleIf: () => window.location.pathname !== '/search/',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: 'nav.navPages .navPages-quickSearch',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-home-ymm',
        class: 'container',
      },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 770,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage',
      location: {
        insertBefore: '.navUser-item--account',
        wrapper: 'li',
        class: 'navUser-item cm_garage__container',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        insertBefore: 'body.template-product .productView-details .productView-options',
      },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: 'body.template-product .productView-description .tabs-contents',
        class: 'tab-content',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        lastChildOf: 'body.template-product .productView-description .tabs',
        class: 'tab cm-desktop-fitment-tab',
        wrapper: 'li',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: 'body.template-category .container .page',
      template: 'facetTiles/hierarchicalContainer',
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
  ],
};
